.dropdown__disabled {
    opacity: 0.4;
}



.dropdown__option{

    background-color:orange !important;

}
/* 
.dropdown > * > .MuiInputBase-input {
    color: var(--light-gray) !important;
} */

.MuiSelect-icon {
    
    color: var(--branco-cinza) !important;
   
}