.editInPlace {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: text;
}

.editInPlace:hover > * {
    color: var(--dark-green)
}

.editInPlace__balance {
    color: var(--green);
    margin: 0;
    padding: 0;
    padding-left: 5px;
    font-size: 1.3rem;
    font-weight: 500;
}

.editInPlace > .MuiSvgIcon-root {
    font-size: 0.9rem;
    color: var(--green);
    margin: 0;
    padding: 0;
}

.editInPlace__input {
    border:none;
    background-image:none;
    background-color:transparent;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
    max-width: 150px !important;
}

.editInPlace__input:focus {
    outline: none;
}
