.header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    /* box-shadow: -1px 20px 20px -6px rgba(11, 185, 185, 0.9); */
    color: var(--main-dark);
}

.header__logo {
    font-size: 1rem;
}

.header__right {
    display: flex;
    font-size: 0.6rem;
}

.header__signIn {
    margin-right: 10px;   
    cursor: pointer; 
}

.header__signUp {
    color: var(--orange);
    cursor: pointer;
    margin-right: 10px;
}

.header__signIn:hover {
    filter: brightness(2.2);    
}

.header__signUp:hover {
    filter: brightness(1.33);
}


.header__logoContainer {
    display: flex;
}

.header__logoO {
    color: var(--main-light);
    filter: brightness(2.2);
}

.header > img {
    object-fit: contain;
    height: 40px;
    padding-top: 8px;
}