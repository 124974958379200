.telaLoginRobo {
    margin: 10px;
    padding: 30px;
    display: flex;
    flex-direction: column;
    box-shadow: 1px 2px 15px -4px rgba(0,0,0,0.2);
    min-width: 400px ;
    max-width: 500px;
    background-color: var(--main-dark);
    color: white;
    position: fixed;
}

.telaLoginRobo__header {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.telaLoginRobo__headerTop {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.telaLoginRobo__headerTop > h1 {
    font-weight: 500;
    font-size: 1.5rem;
    margin-bottom: 5px;
}

.telaLoginRobo__error {
    color: rgba(255, 166, 0, 0.801);
    font-size: small;
}

.telaLoginRobo__header > span {
    opacity: 0.7;
    font-weight:400;
    font-size: 1rem;
    text-align: center;
}

.telaLoginRobo__labels {
    display: flex;
    flex-direction: column;
}

.telaLoginRobo__labels > input {
    display: flex;
    background-color: var(--main-dark);
    border: 1px solid #7E7E7E;
    padding: 8px;
    width: 100%;
    height: 30px;
    color: #EFEFEF;
    border-radius: 3px;
}

.telaLoginRobo__labels > p {
    opacity: 0.7;
    font-weight:400;
    margin-bottom: 2px;
}


.telaLoginRobo__button {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;    
}


.telaLoginRobo__button > .MuiButton-containedPrimary {
    color: #fff;
    background-color: rgba(237,105,24,1)!important;
}

.MuiButtonBase-root:hover {
    filter: brightness(1.2) !important;
}

/* input {
    border: none;
    background-image: none;
    background-color: transparent;
    box-shadow: none;
    max-width: 100px !important;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
}

input:focus {
    outline: none;
}

input {
    font-size: 1rem;
    font-weight: 500;
 
} */

