.button {
    display: flex;
    color: white;
    cursor: pointer;
    width: 120px;
    height: 120px;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    font-size: 0.625rem;
    margin: 3px;
}

@media (max-width: 405px) {
    .button {
        display: flex;
        color: white;
        cursor: pointer;
        width: 100px;
        height: 110px;
        justify-content: center;
        align-items: center;
        border-radius: 5px;
        font-size: 0.625rem;
        margin: 3px;
    }
}


