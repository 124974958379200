@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap');

* {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

a, a:link, a:hover, a:focus, a:active, a:visited {
  text-decoration: none;
  color: inherit;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont,'Poppins', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

:root {
  font-size: 12pt;
  --dark-red: #AC4134;
  --dark-green: #288A3D;
  --orange: #FF7700;
  --green: #38B152;
  --red: #CF5C44;
  --gray: #6b7880;
  --light-gray: #c0c2c9;
  --main-dark: #262e43;
  --main-light: #35415e;
  --darker: #191F2D;
  --laranja: #EBA030;
  --laranja-secundario: #ED9F2E;
  --azul-marinho: #000B48;
  --azul-claro: #0978C5;
  --azul-agua: #3B8CBC;
  --cinza: #3B4460; 
  --marrom: #5b2513bf;
  --verde: #3fbf63bb;
  --vermelho: #ed3e3edc;
  --branco-cinza: rgb(205, 205, 214);
}

@media (min-width: 768px){
  :root {
    font-size: 14pt;
  }
}


/* width */
::-webkit-scrollbar {
  width: 8px;
}

/* Track */
::-webkit-scrollbar-track {
  border-radius: 8px;
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: #99acb8; 
  border-radius: 8px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #acc1ce;
}
