
.mainCard__ganhei {
    background-color: var(--green);
    display: flex;
    flex-direction: column;
    font-size: 1rem;
}

.mainCard__perdi {
    background-color: var(--red);
    display: flex;
    flex-direction: column;
    font-size: 1rem;
}

.mainCard__ganhei:hover {
    background-color: var(--dark-green);
}

.mainCard__perdi:hover {
    background-color: var(--dark-red);
}

.mainCard__config {
    background-color: var(--main-light);
    filter: brightness(1.3);
}

.mainCard__config:hover {
    background-color: var(--main-light);
    filter: brightness(1.4);
}

.mainCard {
    display: flex;
    color: white;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    background-color: var(--darker);
    border-radius: 10px;
    box-shadow: -1px 4px 10px -6px rgba(0,0,0,0.4);
    padding: 0 10px;
    width: 100%;
    height: 280px;
}

.mainCard__topRight {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.mainCard__topLeft {
    color: white;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #262e43;
    padding: 10px 15px;
    border-radius: 10px;
    width: 100%;
    margin-top: 5px;    
}

.mainCard__topLeftOuter {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-right: 7px;
}

.mainCard__topLeftInner {
    display: flex;
    
}

.mainCard__topLeftInner > .mainCard__topLeft > h1 {
    font-size: 0.8;
}

.mainCard__topLeftInner > .mainCard__topLeft > h2 {
    font-size: 0.625rem;
    font-weight: 400;
}


.mainCard__topLeftProx > h1 {
    font-weight: 500 !important;
}

.mainCard__topLeftProx > h2 {
    font-weight: bolder !important;
    color: var(--light-gray);
    font-size: 1rem !important;
    /* color: var(--darker); */
}   

.mainCard__topLeftProx {
    /* background-color: var(--orange); */
    background-color: var(--main-light);
    filter: brightness(1.2);

}

.mainCard__topLeft > h2 {
    font-weight: 300;
    font-size: 0.8;
    margin: 0;
    padding: 0;
    opacity: 0.5;
    letter-spacing: 1.7pt;
}

.mainCard__topLeft > h1 {
    margin: -10px 0;
    padding: 0;
    opacity: 0.9;
    font-weight: 300;
    font-size: 1.125rem;
}

.mainCard__topRight > .mainCard__ganhei > .MuiSvgIcon-root {
    font-size: 38px !important;
}

.mainCard__topRight > .mainCard__perdi > .MuiSvgIcon-root {
    font-size: 38px !important;
}

.mainCard__topLeftProx > h2 {
    font-size: 0.625rem !important;
}



.mainCard__topLeftAcertos > h2 {
    font-weight: 300;
    font-size: 0.55rem;
    margin: 0;
    padding: 0;
    opacity: 0.5;
    letter-spacing: 2.5pt;
}

.mainCard__topLeftAcertos > span {
    font-weight: 400;
    font-size: 0.4rem;
    margin: 0;
    padding: 0;
    opacity: 0.9;
    color: var(--orange);
    letter-spacing: 0.5pt;
}

.mainCard__topLeftAcertos {
    display: flex;
    flex-direction: column;
}


.mainCard__topLeftRetorno > h2 {
    font-weight: 300;
    font-size: 0.55rem;
    margin: 0;
    padding: 0;
    opacity: 0.5;
    letter-spacing: 2.5pt;
}

.mainCard__topLeftRetorno > span {
    font-weight: 400;
    font-size: 0.4rem;
    margin: 0;
    padding: 0;
    opacity: 0.9;
    color: var(--orange);
    letter-spacing: 1pt;
}

.mainCard__topLeftRetorno {
    display: flex;
    flex-direction: column;
}

.mainCard__topRightButtonDownload,
.mainCard__topRightButtonDownloadMobile{

    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: var(--orange);
    padding: 20px 15px;
    border-radius: 5px;
    cursor: pointer;
    margin:5px;
    color:white;
    width: 140px;
    font-size: 0.9rem;
    font-weight: 400;
}

.mainCard__topRightButtonDownloadMobile{

    margin: 0;
    padding: 10px;
    padding-left:-10px;
    justify-content: center;
    width: 140px;
}

.mainCard__topRightButtonDownload:hover, 
.mainCard__topRightButtonDownloadMobile:hover{

    filter:brightness(1.1);
}

@media (max-width: 1015px){
    .mainCard__topRightButtonDownload {
        display: none;
    }  
}

@media (min-width: 1015px){
    .mainCard__topRightButtonDownloadMobile {
        display: none;
    }  
}

@media (max-width: 400px){
    .mainCard__topLeftProx {
        flex-direction: column;
    }  

    .mainCard__topLeftProx > h2 {
        font-size: 0.625rem !important;
        padding-bottom: 5px !important;
        margin-top: -5px !important;
    }

    .mainCard__topLeftProx > h1 {
        font-size: 1.125rem !important;
        margin: 0;
        padding: 0;
    }
}

@media (min-width: 1023px) {
    .mainCard__topLeft > h1 {
        font-size: 1.125rem;
    }

    .mainCard__topLeftRetorno {
        font-size: 0.5rem !important;
    }

    .mainCard__topLeftProx > h2 {
        font-size: 0.8rem !important;
        padding-bottom: 5px !important;
    }

    .mainCard__topLeftProx {
        flex-direction: column;
    }   
    .homeScreenMaincardMobile {
        display: none;
    }

    .mainCard {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        width: 200px;
        height: 100vh;
        background-color: #191f2d83;
    }

    .mainCard__topLeftInner > .mainCard__topLeft > h1 {
        font-size: 0.625rem;
    }
    
    .mainCard__topLeftInner > .mainCard__topLeft > h2 {
        font-size: 0.55rem;
        font-weight: 400;
    }

    .mainCard__topLeft > h2 {
        font-weight: 300;
        font-size: 0.55rem;
        margin: 0;
        padding: 0;
        opacity: 0.5;
        letter-spacing: 0.5pt;
    }

    

    .mainCard__topLeft > h1 {
        margin: -10px 0;
        padding: 0;
        opacity: 0.9;
        font-weight: 300;
        font-size: 0.8;
    }

    .mainCard__topLeft {
        filter: brightness(1.3);
    }

    .mainCard__ganhei {
        margin-top: 5px;
        height: 140px;
        width: 140px;
    }

    .mainCard__perdi {
        height: 140px;
        width: 140px;
    }

    /* .mainCard__topLeftProx {
        display: none;
    }
     */
}

@media (max-width: 1023px) {
    .homeScreenMaincardDesktop {
        display: none;
    }
}

@media (max-width: 405px) {
    .mainCard__topLeftInner > .mainCard__topLeft > h1 {
        font-size: 0.625rem;
    }
    
    .mainCard__topLeftInner > .mainCard__topLeft > h2 {
        font-size: x0.7rem;
        font-weight: 400;
    }

    .mainCard__topLeft > h2 {
        font-weight: 300;
        font-size: 0.7rem;
        margin: 0;
        padding: 0;
        opacity: 0.5;
        letter-spacing: 0.5pt;
    }
    
    .mainCard__topLeft > h1 {
        margin: -10px 0;
        padding: 0;
        opacity: 0.9;
        font-weight: 300;
        font-size: 0.8;
    }
    
    .mainCard__topRight > .mainCard__ganhei > .MuiSvgIcon-root {
        font-size: 30px !important;
    }
    
    
    .mainCard__topRight > .mainCard__perdi > .MuiSvgIcon-root {
        font-size: 30px !important;
    }

    .mainCard__ganhei {
        background-color: var(--green);
        display: flex;
        flex-direction: column;
        font-size: 0.8;
    }
    
    .mainCard__perdi {
        background-color: var(--red);
        display: flex;
        flex-direction: column;
        font-size: 0.8;
    }

    
    

}