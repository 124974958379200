.telaConfigRobo {

    background-color: var(--main-dark);
    width: 650px;
    display: flex;
    flex-direction: column;
    margin:10px;
    font-size: 0.9rem;
    box-shadow: 1px 2px 25px -4px rgba(0,0,0,0.8);

}

@media (max-width: 410px) {
    .desktop {
        display: none !important;
    }  
}
 


@media (min-width: 410px) {
    .mobile {   
        display: none !important;
    }
}



.telaConfigRobo__buttonCancelar {
    margin-right: 10px !important;
}

@media (max-width: 670px) {
    .telaConfigRobo {
        width: 600px;
    }
    .telaConfigRobo__espaço {
        margin: 20px 0 !important;
    }
}

@media (max-width: 610px) {
    .telaConfigRobo {
        width: 580px;
    }
}

@media (max-width: 590px) {
    .telaConfigRobo {
        width: 500px;
    }
    .telaConfigRobo__telaInterna {
        padding: 30px 50px !important;
    
    }
}

@media (max-width: 510px) {
    .telaConfigRobo {
        width: 450px;
    }
    .telaConfigRobo__telaInterna {
        padding: 30px 40px !important;
    
    }
}

@media (max-width: 460px) {
    .telaConfigRobo {
        width: 400px;
    }
    .telaConfigRobo__telaInterna {
        padding: 30px 40px !important;
    
    }
}

@media (max-width: 410px) {
    .telaConfigRobo {
        width: 320px;
    }
    .telaConfigRobo__telaInterna {
        padding: 30px 20px !important;
    
    }
}

.telaConfigRobo__barraSuperior {

    padding:10px 0px;
    background-color: var(--darker);
    color:#92959d;
    display: flex;
    align-items: center;
    justify-content: space-between;

}

.telaConfigRobo__barraSuperior > h1 {

    color: var(--branco-cinza);
   margin: 0;
   font-weight: 400;
   font-size: 1rem;
    padding-left: 20px;

}

.telaConfigRobo__barraSuperior > .MuiSvgIcon-root{

    padding-right: 10px;
    cursor: pointer;

}

.telaConfigRobo__barraSuperior > .MuiSvgIcon-root:hover{
    filter: brightness(1.2);
}

.telaConfigRobo__telaInterna {

    padding: 30px 70px;

}
.telaConfigRobo__barraInterna {

    border-radius:4px;
    display: flex;
    justify-content: space-between;

}
.telaConfigRobo__barraInternaDig {

    background-color: var(--main-light);
    width: 100%;
    border-radius: 2px;
    padding:5px;
    display: flex;
    justify-content: center;
    color: var(--light-gray);
    cursor: pointer;
    filter: brightness(1.1);
    opacity: 0.9;

}

.telaConfigRobo__barraInternaDig:hover {
    filter: brightness(1.2);
}

.telaConfigRobo__barraInternaBin:hover {
    filter: brightness(1.1);
}

.telaConfigRobo__barraInternaBin {
    background-color: var(--darker);

    width: 100%;
    border-radius: 2px;
    padding:5px;
    display: flex;
    justify-content: center;
    color:#92959d;
    cursor: pointer;
}

.telaConfigRobo__config {

    margin-top: 12px;

}

.telaConfigRobo__espaço {

    margin: 30px 0;
    opacity: 0.2;
    height: 1px;
    border: none;
    color: var(--branco-cinza);
    background-color:var(--branco-cinza);

}

.telaConfigRobo__buttons {

    display: flex;
    justify-content: space-between;

}

.telaConfigRobo__buttonsDireita {
    display: flex;

}

.telaConfigRobo__salvar{

    background-color: #ee761a !important;
}

.telaConfigRobo__salvar > *{

    color: white !important;
}

.text-area {    
    display: flex;
    border: 1px solid gray !important;
    min-width: 400px !important;
    min-height: 115px;
}

.textArea__container {
    display: flex;
}

.react-datepicker-ignore-onclickoutside {
    border: none;
    background-image: none;
    background-color: transparent;
    box-shadow: none;
    max-width: 100px !important;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    color: white;

}

.react-datepicker-ignore-onclickoutside:focus {
    outline: none;
}

.react-datepicker__input-container > input {
    border: none;
    background-image: none;
    background-color: transparent;
    box-shadow: none;
    max-width: 100px !important;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    color: white;
}