.card {
    display: flex;
    flex-direction: column;
    color: white;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    background-color: var(--main-light);
    height: 100px;
    border-radius: 10px;
    box-shadow: -1px 4px 10px -6px rgba(0,0,0,0.4);
    padding: 0 10px;
    width: 100%;
}

.card__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 15px;
}

.card__title {
    font-size: 0.625rem;
    font-weight: 500;    
}

.card__frequency {
    display: flex;
    font-size: 0.55rem;
    background-color: var(--orange);
    border-radius: 5px;
    justify-content: center;
    align-items: center;
    font-weight: 500;
    padding: 0 5px;
}

.card__descriptionDays {
    display: flex;
    justify-content: center;
    align-items: center;
}

.card__balance {
    display: flex;
    width: 100%;
    justify-content: flex-start;
    font-size: 1.125rem;
}

.card__bottom {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    height: 15px;
}
.card__compound {
    color: var(--green);
    background-color: rgba(89, 255, 47, 0.1);
    border-radius: 2px;
    padding: 0 1px;
    font-size: 0.625rem;
    font-weight: 600;
}


.card__negativeCompound {
    color: var(--red);
    background-color: rgba(255, 71, 47, 0.1);
    border-radius: 2px;
    padding: 0 1px;
    font-size: 0.625rem;
    font-weight: 600;
}

.card__description {
    font-size: 0.625rem;
    color: var(--light-gray);
    margin-left: 1em;
    opacity: 0.85;
}