.softwarePhotos {
    width: 100%;
    height: 100%;
}

.softwarePhotos__inner {
    display: flex;
    flex-direction: column;
    margin-top: 20px;
}

.softwarePhotos__inner > img {
    display: flex;
    object-fit: contain;
    width: 100%;
}

.softwarePhotos__inner > h1 {
    font-size: 1rem;
    color: var(--orange);
    text-align: center;
    position: absolute;
    left: 50%;
    transform: translate(-50%, -50%);
    margin-top: 20px;

}

@media (min-width: 768px) {
    .softwarePhotos__inner > h1 {
        margin-top: 50px;
    }
}
