.cardConfig{
    display: flex;
    justify-content: space-between;
    color: var(--branco-cinza);
    padding: 15px 0;
    align-items: center;
    font-weight: 400;
    font-size: 0.9rem;
}

.cardConfig__input {
    border: none;
    background-image: none;
    background-color: transparent;
    box-shadow: none;
    max-width: 100px !important;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
}

.cardConfig__input:focus {
    outline: none;
}

.cardConfig__inputStyles {
    color: var(--branco-cinza);
    font-weight: 400;
    font-size: 0.9rem;
}

.cardConfig__editSpan {
    display: flex;
    align-items: center;
}

.cardConfig__editSpan > * {
    font-size: 0.9rem !important;
    cursor: pointer !important;
}

.cardConfig__disabled {
    opacity: 0.2;

}