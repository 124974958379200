.simpleButton{

    display: flex;
    background-color:var(--main-light);
    filter:brightness(1.1);
    align-items: center;
    justify-content: center;
    padding: 8px;
    border-radius: 4px;
    width: 100px !important;
    cursor: pointer;
}

@media (max-width: 510px) {
    .simpleButton {
        font-size: 0.8rem;
        padding: 4px;
    }
}

.simpleButton > *{

    color:var(--light-gray);
    font-weight: 500;
    align-items: center;
    justify-content: center;
    opacity: 0.9;

}

.simpleButton:hover {
    filter: brightness(1.2);
}

.simpleButton__salvar{

    background-color: #ee761a !important;
}

.simpleButton__salvar > *{

    color: white !important;
}