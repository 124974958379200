.termos {
    display: flex;
    justify-content: center;
    align-items: center;
}

.termos__container {
    padding-left: 20px;
    padding-top: 20px;
    max-width: 1400px;
}


.site_link {
    margin-left: 5px;
}

.site_link:hover {
    filter: brightness(1.5) !important;
}

@media (max-width: 520px) {
    .termos__container > h1 {
        font-size: 1.5rem;
    }

    .termos__container {
        font-size: 0.7rem;
    }
    .termos {
        padding: 0 20px;
    }
}