.mainCard__topLeftDesktop {
    color: white;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #262e43;
    padding: 10px 15px;
    border-radius: 10px;
    width: 100%;
    margin-top: 5px;    
}

.mainCard__topLeftInnerDesktop {
    display: flex;
    
}

.mainCard__topLeftInnerDesktop > .mainCard__topLeftDesktop > h1 {
    font-size: 0.8;
}

.mainCard__topLeftInnerDesktop > .mainCard__topLeftDesktop > h2 {
    font-size: 0.625rem;
    font-weight: 400;
}

.mainCard__green {
    color: var(--green) !important;
    filter: brightness(1.5);
}


.mainCard__topLeftProxDesktop > h1 {
    font-weight: 500 !important;
}

.mainCard__topLeftProxDesktop > h2 {
    font-weight: bolder !important;
    color: var(--light-gray);
    font-size: 1rem !important;
    /* color: var(--darker); */
}   

.mainCard__topLeftProxDesktop {
    /* background-color: var(--orange); */
    background-color: var(--main-light);
    filter: brightness(1.2);
    margin-top: 15px;    

}

.mainCard__topLeftDesktop > h2 {
    font-weight: 300;
    font-size: 0.8;
    margin: 0;
    padding: 0;
    opacity: 0.5;
    letter-spacing: 1.7pt;
}

.mainCard__topLeftDesktop > h1 {
    margin: -10px 0;
    padding: 0;
    opacity: 0.9;
    font-weight: 300;
    font-size: 1.125rem;
}

.mainCard__topLeftProxDesktop > h2 {
    font-size: 0.625rem !important;
}

.mainCard__topLeftAcertosDesktop > h2 {
    font-weight: 300;
    font-size: 0.55rem;
    margin: 0;
    padding: 0;
    opacity: 0.5;
    letter-spacing: 2.5pt;
}

.mainCard__topLeftAcertosDesktop > span {
    font-weight: 400;
    font-size: 0.4rem;
    margin: 0;
    padding: 0;
    opacity: 0.9;
    color: var(--orange);
    letter-spacing: 0.5pt;
}

.mainCard__topLeftAcertosDesktop {
    display: flex;
    flex-direction: column;
}


.mainCard__topLeftRetornoDesktop > h2 {
    font-weight: 300;
    font-size: 0.55rem;
    margin: 0;
    padding: 0;
    opacity: 0.5;
    letter-spacing: 2.5pt;
}

.mainCard__topLeftRetornoDesktop > span {
    font-weight: 400;
    font-size: 0.4rem;
    margin: 0;
    padding: 0;
    opacity: 0.9;
    color: var(--orange);
    letter-spacing: 1pt;
}

.mainCard__topLeftRetornoDesktop {
    display: flex;
    flex-direction: column;
}

@media (max-width: 400px){
    .mainCard__topLeftProxDesktop {
        flex-direction: column;
    }  

    .mainCard__topLeftProxDesktop > h2 {
        font-size: 0.625rem !important;
        padding-bottom: 5px !important;
        margin-top: -5px !important;
    }

    .mainCard__topLeftProxDesktop > h1 {
        font-size: 1.125rem !important;
        margin: 0;
        padding: 0;
    }
}

@media (min-width: 1023px) {
    .mainCard__topLeftDesktop > h1 {
        font-size: 3rem;
    }

    .mainCard__topLeftRetornoDesktop {
        font-size: 0.5rem !important;
    }

    .mainCard__topLeftProxDesktop > h2 {
        font-size: 1.5rem !important;
        padding-bottom: 5px !important;
    }

    .mainCard__topLeftProxDesktop {
        flex-direction: column;
       
    }   
    .homeScreenMaincardMobile {
        display: none;
    }

    .mainCard__topLeftInnerDesktop > .mainCard__topLeftDesktop > h1 {
        font-size: 0.625rem;
    }
    
    .mainCard__topLeftInnerDesktop > .mainCard__topLeftDesktop > h2 {
        font-size: 0.55rem;
        font-weight: 400;
    }

    .mainCard__topLeftDesktop > h2 {
        font-weight: 300;
        font-size: 0.55rem;
        margin: 0;
        padding: 0;
        opacity: 0.5;
        letter-spacing: 0.5pt;
    }

    .mainCard__topLeftDesktop > h1 {
        margin: -10px 0;
        padding: 0;
        opacity: 0.9;
        font-weight: 300;
        font-size: 0.8;
    }

    .mainCard__topLeftDesktop {
        filter: brightness(1.3);
    }
    
}

@media (max-width: 1023px) {
    .homeScreenMaincardDesktop {
        display: none;
    }
}

@media (max-width: 405px) {
    .mainCard__topLeftInnerDesktop > .mainCard__topLeftDesktop > h1 {
        font-size: 0.625rem;
    }
    
    .mainCard__topLeftInnerDesktop > .mainCard__topLeftDesktop > h2 {
        font-size: 0.7rem;
        font-weight: 400;
    }

    .mainCard__topLeftDesktop > h2 {
        font-weight: 300;
        font-size: 0.7rem;
        margin: 0;
        padding: 0;
        opacity: 0.5;
        letter-spacing: 0.5pt;
    }
    
    .mainCard__topLeftDesktop > h1 {
        margin: -10px 0;
        padding: 0;
        opacity: 0.9;
        font-weight: 300;
        font-size: 0.8;
    }
    
}


