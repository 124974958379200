.doughnutChart {
    display: flex;
    flex-direction: column;
    color: white;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    background-color: var(--main-light);
    height: auto;
    border-radius: 10px;
    box-shadow: -1px 4px 10px -6px rgba(0,0,0,0.4);
    padding: 0 10px;
    padding-bottom: 10px;
    margin-top: 15px;
}

.doughnutChart__header {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
    height: 15px;
    margin-bottom: 10px;
}

.doughnutChart__title {
    font-size: 0.625rem;
    font-weight: 500;    
}

.doughnutChart__table {
    display: flex;
    flex-direction: column;
    width: 80%;   
}   

.doughnutChart__table > * {
    padding-left: 10px;
}

.doughnutChart__tableHeader {
    display: flex;
    justify-content: space-between;
}

.doughnutChart__tableHeader > h1 {
    font-size: 0.55rem;
    font-weight: bold;   
}

.doughnutChart__tableRow {
    display: flex;
    border-top: 1px solid rgba(211, 211, 211, 0.2);
}

.doughnutChart__tableRow > p {
    font-size: 0.55rem;
    font-weight: 500; 
    flex-basis: 100%;   
}

.doughnutChart__tableRowWins {
    color: var(--green);
    font-size: 0.55rem;
    font-weight: 600;
}

.doughnutChart__tableRowLosses {
    color: var(--red);
    font-size: 0.55rem;
    font-weight: 600;

    /* font-size: 0.55rem;
    font-weight: 400;
    background-color: var(--red);
    padding: 0 5px;
    border-radius: 5px;
    align-self: center;
    justify-self: center; */
}

