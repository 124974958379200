.MuiListItem-root.Mui-selected, .MuiListItem-root.Mui-selected:hover {
    background-color: rgba(0, 0, 0, 0.08);
}


/* 
.MuiButtonBase-root {
    color: black !important;
    opacity: 1;
} */

.MuiInputBase-input {
    color: var(--light-gray) !important;
    
}

