.faq > h1 {
    font-size: 1rem;
    color: var(--orange);
    text-align: center;  
    
}

.faq {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-self: center;
    margin: 20px;
    max-width: 1200px;
    padding: 40px 0;
}