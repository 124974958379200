.barChart {
    display: flex;
    flex-direction: column;
    color: white;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    background-color: var(--main-light);
    height: auto;
    border-radius: 10px;
    box-shadow: -1px 4px 10px -6px rgba(0,0,0,0.4);
    padding: 0 10px;
    padding-bottom: 10px;
    margin-top: 15px;
}


.barChart__header {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
    height: 15px;
    margin-bottom: 10px;
}

.barChart__title {
    font-size: 0.625rem;
    font-weight: 500;    
}



.barChart__chart {
    width: 100%;
    height: 100%;
}





