.MuiTabs-flexContainer > .MuiButtonBase-root {
    color: var(--light-gray) !important;
    opacity: 0.9;
}

.MuiTab-textColorSecondary.Mui-selected {
    color: white !important;
    opacity: 1;
}

.MuiAppBar-colorDefault {
    background-color: var(--main-light) !important;
    border-radius: 10px !important;
}

.basic {
    margin-bottom: 15px;
    display: flex;
    justify-content: space-between;
    align-items: space-between;
}

.basic > * {
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
}

.MuiSelect-icon {
    color: var(--light-gray) !important;
}
