.history {
    display: flex;
    flex-direction: column;
    color: white;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    background-color: var(--main-light);
    max-height: 100vh;
    border-radius: 10px;
    box-shadow: -1px 4px 10px -6px rgba(0,0,0,0.4);
    padding: 0 10px;
    padding-bottom: 10px;
    margin-top: 15px;
    overflow-y: scroll;
}

.history__header {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
    height: 15px;
    margin-bottom: 10px;
}

.history__title {
    font-size: 0.625rem;
    font-weight: 500;    
}

.history__table {
    display: flex;
    flex-direction: column;
    width: 100%;
    min-width: 180px;
}   


.history__tableRow {
    display: flex;
    justify-content: space-evenly;
    background-color: var(--main-dark);
    padding: 5px 0;
    border-radius: 5px;
    margin-top: 3px;
}


.history__tableRow:hover {
    filter: brightness(1.1);
}
.history__tableRowLeft > * {
    margin: 0;   
    display: flex;
    flex-basis: 100%;    
}

.history__tableRowRight > * {
    margin: 0;
    flex-basis: 100%; 
}

.history__tableRowLeft > h3 {
    display: flex;
    flex-direction: column;
    font-size: 0.55rem;
    font-weight: 400;
}

.history__tableRowRight > h3{
    display: flex;
    flex-direction: column;
    font-size: 0.55rem;
    font-weight: bold;
    color: var(--green);
}

.history__tableRowLeft > h5 {
    display: flex;
    flex-direction: column;
    font-size: 8px;
    font-weight: 400;
    color: var(--gray);
}

.history__tableRowRight > h5{
    display: flex;
    flex-direction: column;
    font-size: 8px;
    font-weight: 500;
    color: var(--gray);
    opacity: 0.8;
}

.history__tableRowRight > .history__tableRowRightLoss {
    color: var(--red);
}

.history__tableRowLeft {
    padding-left: 1em;
    width: 100%;
}

.history__tableRowRight {
    width: 100%;
}




