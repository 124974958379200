.videoSection > h1 {
    font-size: 1rem;
    color: var(--orange);
    text-align: center;  
}

.videoSection {
    display: flex;
    flex-direction: column;
    justify-content: center !important;
    align-self: center !important;
    margin: 20px 0;
    min-width: 280px;
    width: 125%;
    padding: 40px 0;
}

.videoSection__carousel {
    display: flex;
    justify-content: center;
    padding: 0;
    margin: 0;
}