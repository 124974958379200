.nav {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: var(--darker);
    height: 45px;
}

.nav__logo {
    width: 85px;
    object-fit: contain;
    padding: 2px 5px;
}

.nav__text {
    display: flex;
    padding-left: 10px;
    cursor: pointer;
}

.nav__text:hover {
    filter: brightness(1.1);
}

.nav__text > h1 {
    color: var(--light-gray);
    font-size: 1rem;
    font-weight: 500;
    
}

.config_adjustments {
    height: 20px !important;
    width: 20px !important;
    padding: 0 !important;
}

.nav__textO {
    color: var(--main-light) !important;
    opacity: 1 !important;
    filter: brightness(2.5);
    
}

.nav__right {
    display: flex;
    padding: 0 5px;
    justify-content: center;
    align-items: center;
  
}

.nav__rightEdit {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: text;
}

.nav__rightEdit:hover > * {
    color: var(--dark-green)
}

.nav__reset {
    background-color: var(--main-light);
    color: rgba(255, 255, 255, 0.815);
    border-radius: 5px;
    padding: 0 5px;
    font-size: 0.8rem;
    cursor: pointer;
}

.nav__reset:hover {
    filter: brightness(1.2);
}

.nav__selectPlan--Desktop {
    display: flex;
}

@media (max-width: 530px) {
    .nav__selectPlan--Desktop {
        display: none;
    }

    .nav > img {
        height: 25px !important;
    }
}

@media (max-width: 1015px) {
    .nav__roboContainer {
        display: none;
    }
}

.nav > img {
    object-fit: contain;
    height: 40px;
    padding-top: 8px;
    padding-left: 10px;
    cursor: pointer;
    opacity: 0.9;
}

.nav > img:hover {
    filter: brightness(1.1);
    opacity: 1;
}